import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import ImageMeta from "../ImageMeta";
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"


var classNames = require("classnames")
var showdown = require("showdown")

function createHtml(html) {
  return { __html: html }
}

class FeaturedList extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {

    var featuredList = this.props.listContent

    const converter = new showdown.Converter()

    let featuredListClasses = classNames("body-section featured-list color-back", {
      "picture-icons": featuredList.hasPictureIcons
    })

    return (
      <div className={featuredListClasses}>
        <div className="columns" style={{ paddingBottom: "32px" }}>
          <div className={`column is-${this.props.headingSideColumnIs}`}></div>
          <div className="column">
          <MarkdownViewer markdown={featuredList.featuredListHeading} />
          {featuredList.featuredListBlurb && <MarkdownViewer className="featured-blurb" markdown={featuredList.featuredListBlurb} />}
          </div>
          <div className={`column is-${this.props.headingSideColumnIs}`}></div>
        </div>
        <div className="columns">
          <div className={`column is-${this.props.listSideColumnIs}`}></div>
          <div className="column">
            {featuredList.featuredListItems.map((listItem, i) => {
              let item = listItem.featuredListItem;
              return (
                <div key={item.featuredListItemHeading} className="reasons-why-grid-row-single">
                  <div className="reason-number-wrapper">
                    <span className={`reason-number-circle${item.iconId ? " icon-circle" : ""}`}>{item.iconId ?
                                <ImageMeta
                                cloudName="nuvolum"
                                publicId={item.iconId}
                                width="auto"
                                responsive
                              ></ImageMeta>
                    : 
                     i + 1
                    }</span>
                  </div>
                  <div>
                    {featuredList.hasPictureIcons ? <h5>{item.featuredListItemHeading}</h5> : <h3>{item.featuredListItemHeading}</h3>}
                    <MarkdownViewer className="large-p" markdown={item.featuredListItemBlurb} />
                  </div>
                </div>
              )

            })}

          </div>
          <div className={`column is-${this.props.listSideColumnIs}`}></div>
        </div>
      </div>
    )
  }
}

FeaturedList.propTypes = {
  listContent: PropTypes.object,
  textSection: PropTypes.string,
  headingSideColumnIs: PropTypes.number,
  listSideColumnIs: PropTypes.number,
  featuredListHeading: PropTypes.string,
}

export default FeaturedList;