import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import ImageMeta from "../ImageMeta";

var classNames = require("classnames")

import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"

class Figures extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {

    let Images;

    if (this.props.images.length === 1) {
      Images = <div className="column last">
        <ImageMeta
          cloudName="nuvolum"
          publicId={this.props.images[0]}
          width="auto"
          responsive
        ></ImageMeta>
      </div>
    } else if (this.props.images.length === 2) {
      Images = <><div className="column is-5">
        <ImageMeta
          cloudName="nuvolum"
          publicId={this.props.images[0]}
          width="auto"
          responsive
        ></ImageMeta>
      </div>
        <div className="column"></div>
        <div className="column last is-5">
          <ImageMeta
            cloudName="nuvolum"
            publicId={this.props.images[1]}
            width="auto"
            responsive
          ></ImageMeta>
        </div>
      </>
    }


    return (
      <section className="body-sections section joshua-tree-content has-text-centered figures" style={{ paddingTop: this.props.paddingTop, paddingBottom: this.props.paddingBottom }}>
        <div className="columns">
          <div className={`column is-${this.props.sideColumnIs}`}></div>
          {Images}
          <div className={`column is-${this.props.sideColumnIs}`}></div>
        </div>
        <span className="caption">{this.props.caption}</span>
      </section>
    )
  }
}

Figures.propTypes = {
  textSection: PropTypes.string.isRequired,
  sideColumnIs: PropTypes.number,
}

export default Figures
